import React, { useEffect, useState, useRef } from 'react';
import HeaderBar from '../../../components/header-bar';
import CategoryProductItem from '../../../components/category-product-item';
import demoImageProduct from '../../../images/demo/category-product.png';
import { navigate } from 'gatsby';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import store, { actions, getters } from '../../../redux/store';
import { useSelector } from 'react-redux';
import {
  setViewSearchResults,
  setViewItemListOnSearch,
} from '../../../utils/_ga';
import WebFn, { callAppFunc } from '../../../utils/jsbridge/index.js';
import { pageCheck } from '../../../utils/pageCheck';
import { setSearchAddToCartFailed } from '../../../utils/_RateData.js';

import ContentLoader from 'react-content-loader';
import { setSelectItem } from '../../../utils/_ga';

import { setAppsFlyerSearch } from '../../../utils/_appsflyer.js';

let currentPage = 1;
let totalPage = 0;
const perPage = 36;
let arrProducts = [];

const SearchProductPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 1000`}
        height={1000}
        width={358 * ratio}
        backgroundColor="#FFFFFF"
        foregroundColor="#dedede"
      >
        {[...Array(5)].map((i, index) => {
          return (
            <>
              {[...Array(3)].map((j, subIndex) => {
                return (
                  <>
                    <rect
                      x={subIndex * 127 * ratio}
                      y={20 + 220 * index}
                      rx="8"
                      ry="8"
                      width={105 * ratio}
                      height="140"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={170 + 220 * index}
                      rx="0"
                      ry="0"
                      width={30 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={190 + 220 * index}
                      rx="0"
                      ry="0"
                      width={105 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={210 + 220 * index}
                      rx="0"
                      ry="0"
                      width={80 * ratio}
                      height="16"
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const SearchResult = ({ location, keyword }) => {
  const api = useAPI();

  const [searchWord, setSearchWord] = useState('');
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [showNoData, setShowNoData] = useState(false);

  const storeId = useSelector((state) => getters.getStoreId(state));
  const hashAccount = useSelector((state) => getters.getHashAccount(state));
  const successOrderCount = useSelector((state) =>
    getters.getSuccessOrderCount(state)
  );

  // getters取得attribution token
  const attributionToken = useSelector((state) =>
    getters.getAttributionToken(state)
  );

  const localCacheData = useSelector((state) => getters.getLocalCache(state));
  const keepaliveData = useSelector((state) => getters.getKeepalive(state));

  const PAGE_CACHE_KEY = 'searchResultPage';
  const pageName = '搜尋結果頁';

  const resultList = useRef();

  const getInfo = (fromCache = false) => {
    api
      .getInfo()
      .then((res) => {
        // dismissLoading(true);

        const newProds = [];
        arrProducts.forEach((p) => {
          let result = res.cart.productCount.find((infoProd) => {
            return p.id === infoProd.productNo;
          });
          const asProd = Object.assign({}, p);

          if (result !== undefined) {
            asProd.qty = result.count;
          } else {
            asProd.qty = 0;
          }
          newProds.push(asProd);
        });

        setShowPage(true);
        setResults(arrProducts);

        if (fromCache) {
          const offsetData = keepaliveData[`search-result-offset`];

          if (offsetData !== undefined && offsetData !== null) {
            console.log('position = ', offsetData.position);
            // setTimeout(() => {
            //   window.scrollTo(0, offsetData.position);
            // }, 100);
            window.scrollTo(0, offsetData.position);
          }
        }
        // setViewItemList(keyword, arrProducts, pageName);
      })
      .catch((error) => {
        // dismissLoading(true);
        console.log(error);
      });
  };

  const handleSearchAddToCartFailed = () => {
    if (successOrderCount >= 4) setSearchAddToCartFailed(true);
  };

  const doSearchResult = (word) => {
    if (arrProducts.length <= 0) {
      const resultsCache = localCacheData[`${PAGE_CACHE_KEY}-${word}`];

      if (resultsCache !== undefined && resultsCache !== null) {
        currentPage = resultsCache.currentPage;
        totalPage = resultsCache.totalPage;
        arrProducts = resultsCache.results;
        setHasMore(resultsCache.hasMore);
        getInfo(true);

        if (resultsCache.results.length === 0) {
          setShowNoData(true);
        } else {
          setShowNoData(false);
          setShowPage(true);
        }
        return;
      }
    }

    setShowNoData(false);

    if (currentPage === 1 || currentPage <= totalPage) {
      setHasMore(true);

      word = word === undefined ? searchWord : word;

      if (currentPage === 1) {
        setShowPage(false);
        // showLoading(true);
        const searchJsonString = localStorage.getItem('search');
        if (searchJsonString !== null) {
          const searchJson = JSON.parse(searchJsonString);
          let isIn = false;
          for (const element of searchJson) {
            if (element === word) {
              isIn = true;
            }
          }
          if (!isIn) {
            searchJson.reverse().push(word);
            localStorage.setItem(
              'search',
              JSON.stringify(searchJson.reverse().slice(0, 5))
            );
          }
        } else {
          const searchs = [];
          searchs.push(word);
          localStorage.setItem('search', JSON.stringify(searchs));
        }
      }
      api
        .searchProduct({
          keyword: word,
          currentPage,
          perPage,
          storeId,
          visitorId: hashAccount,
        })
        .then((res) => {
          if (!res) {
            handleSearchAddToCartFailed();
            // dismissLoading(true);
            return;
          }

          const rowArr = res.rows;
          //加星計畫參數寫入
          if (rowArr.length === 0) handleSearchAddToCartFailed();
          arrProducts = [...arrProducts, ...rowArr];
          if (currentPage === 1) {
            setViewSearchResults(hashAccount, word, res);
            setAppsFlyerSearch(word, res);
            if (rowArr.length === 0) {
              setSearchWord(word);
              setShowNoData(true);
            } else {
              setShowNoData(false);
            }
          }

          store.dispatch(actions.setAttributionToken(res.attributionToken));

          setViewItemListOnSearch(hashAccount, word, res, res.attributionToken);

          currentPage = currentPage + 1;
          totalPage = Number.parseInt(res.total / perPage);
          if (res.total % perPage !== 0) {
            totalPage++;
          }

          if (res.total === res.rows.length) {
            setHasMore(false);
          }

          getInfo();
          // setUpdatePage(true);
        })
        .catch((error) => {
          handleSearchAddToCartFailed();
          // dismissLoading(true);
          console.log(error);
        });
    } else {
      setShowPage(true);
      setHasMore(false);
    }
  };

  useEffect(() => {
    arrProducts = [];
    currentPage = 1;
    totalPage = 0;

    const searchQuery = localStorage.getItem('searchQuery');
    if (searchQuery != null) {
      setSearchWord(searchQuery);
      doSearchResult(searchQuery);
    } else {
      setSearchWord(keyword);
      doSearchResult(keyword);
    }
  }, []);

  const saveCache = () => {
    const cacheData = {
      key: `${PAGE_CACHE_KEY}-${searchWord}`,
      data: {
        currentPage: currentPage,
        totalPage: totalPage,
        results: results,
        hasMore: hasMore,
      },
    };
    store.dispatch(actions.addLocalCache(cacheData));
  };

  return (
    <>
      <HeaderBar
        search
        onSearchKeyChange={(e) => {
          console.log(e);
        }}
        goBack={() => {
          saveCache();
          store.dispatch(actions.cleanKeepalive());
          localStorage.removeItem('searchQuery');
          callAppFunc('backHome', {});
        }}
        onEnter={(searchKey) => {
          saveCache();
          arrProducts = [];
          currentPage = 1;
          totalPage = 0;
          setResults([]);
          localStorage.setItem('searchQuery', searchKey);
          setSearchWord(searchKey);
          doSearchResult(searchKey);
          store.dispatch(actions.cleanKeepalive());
        }}
      />
      <div className="search-result-page">
        <h2 className="result-title">搜尋“{searchWord}”的結果</h2>
        {showPage && !showNoData ? (
          <div>
            {results.length > 0 ? (
              <InfiniteScroll
                ref={resultList}
                dataLength={results.length}
                next={doSearchResult}
                hasMore={hasMore}
                style={{ height: '100%', overflow: 'unset' }}
                loader={<h6>資料載入中...請稍候</h6>}
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
              >
                <div className="result-list">
                  {results &&
                    results.map((product, index) => (
                      <CategoryProductItem
                        data={product}
                        pageName={pageName}
                        searchWord={searchWord}
                        update={() => {
                          getInfo();
                        }}
                        goNext={(id) => {
                          pageCheck(api, () => {
                            saveCache();
                            console.log(
                              'position',
                              resultList.current.el.pageYOffset
                            );

                            const keepaliveData = {
                              key: `search-result-offset`,
                              data: {
                                position: resultList.current.el.pageYOffset,
                              },
                            };
                            store.dispatch(actions.addKeepalive(keepaliveData));

                            setSelectItem(keyword, product, pageName);
                            navigate(`/product/${id}`, {
                              state: {},
                              replace: false,
                            });
                          });
                        }}
                        key={`${index}-${product.id}`}
                      />
                    ))}
                </div>
              </InfiniteScroll>
            ) : undefined}
          </div>
        ) : !showPage ? (
          <SearchProductPreview />
        ) : undefined}
        {showNoData ? (
          <div className="no-result-information">
            抱歉沒有找到與 <strong>"{searchWord}"</strong> 相關的產品
            <br />
            Currently, our search engine could only support Chinese.
            <br />
            Sorry for the inconvenience.
          </div>
        ) : undefined}
      </div>

      <style jsx>
        {`
          .search-result-page {
            background-color: #f2f2f2;
            padding: 16px;
            min-height: calc(100% - 55px);
            box-sizing: border-box;

            .result-title {
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              margin-bottom: 8px;
            }

            .result-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              row-gap: 8px;
              column-gap: 8px;
            }

            .no-result-information {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              box-sizing: border-box;
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default SearchResult;
